import React, { FunctionComponent, ReactNode } from "react"

/** svg */
import ListenApplePodcasts from "../../../assets/listen-apple-podcasts.svg"
import ListenGooglePodcasts from "../../../assets/listen-google-podcasts.svg"
import ListenIheartRadioPodcasts from "../../../assets/listen-iheart-radio.svg"
import ListenPandoraPodcasts from "../../../assets/listen-pandora.svg"
import ListenSpotifyPodcasts from "../../../assets/listen-spotify.svg"
import ListenAmazonPodcasts from "../../../assets/listen-amazon.svg"
import ListenSticherPodcasts from "../../../assets/listen-stitcher.svg"
import ListenPodcastAddictPodcasts from "../../../assets/listen-podcast-addict.svg"
import ListenOvercastPodcasts from "../../../assets/listen-overcast.svg"
import ListenPocketCastsPodcasts from "../../../assets/listen-pocket-casts.svg"
import ListenPodcastIndexPodcasts from "../../../assets/listen-podcast-index.svg"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  icon: any
  iconTitle: string
  title: string
  subTitle?: string
  formatSubTitle?: () => ReactNode
  image: string
  imageAlt: string
  textColor: string
  buttonName?: string
  buttonUrl?: string
  starRating?: string
  subNote?: any
  asoEffect: string
}

/** const */
const HeroBottomShowSection: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  icon: Icon,
  iconTitle,
  title,
  subTitle,
  formatSubTitle,
  image,
  imageAlt,
  textColor,
  buttonName,
  buttonUrl,
  starRating,
  subNote,
  asoEffect,
}) => {
  const renderedSubTitle = subTitle ?? formatSubTitle()

  return (
    <section
      className="container-fluid text-center pt-5 pb-0"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <a id="NavVideo"></a>
      <div className="container pl-3 pr-3">
        <div className="container pb-5"></div>
        <div className="text-center pb-2"></div>
        <div className="hidden-sm hidden-md hidden-lg hidden-xl">
          <img src="/shows/zero-to-unicorn/zero-to-unicorn-icon.png" />
        </div>

        <hr
          className="contentHeroTitle hidden-sm hidden-md hidden-lg hidden-xl style-line mb-3 mt-3"
          style={{ borderColor: "rgba(0, 0, 0, 0.2)" }}
        />
        <h3 className="contentHeroTitle" style={{ color: "#FFFFFF" }}>
          {title}
        </h3>
        <h4 className="contentHeroSubTitle mt-0 mb-5" style={{ color: "#FFFFFF", opacity: "0.6" }}>
          {renderedSubTitle}
        </h4>

        <div className="d-flex justify-content-center mt-5 mb-5">
          <a
            className="btn btn-md btn-light animate__animated animate__animated animate__pulse animate__infinite animate__slow"
            href={buttonUrl}
            target="_blank"
            style={{
              backgroundColor: "#ffffff",
              border: "0px",
              color: "#333333",
            }}
          >
            {buttonName}
          </a>
        </div>

        {/* <div className="container">
          <div className="row">
            <iframe
              className="youtube-video"
              src="https://www.youtube.com/embed/jjocftqFqaw"
              title="Unveiling the Billion-Dollar Journey - Zero to Unicorn Trailer"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div> */}

        {/* <div className="d-flex justify-content-center">
          <a
            className="ms-1 me-1"
            href="https://podcasts.apple.com/us/podcast/zero-to-unicorn/id1685311966"
            target="_blank"
          >
            <ListenApplePodcasts />
          </a>
          <a
            className="ms-1 me-1"
            href="https://open.spotify.com/show/1W9wB8Z4gPFVOgd6reFD9y?si=93b3fb5e2a7c44c7"
            target="_blank"
          >
            <ListenSpotifyPodcasts />
          </a>
          <a
            className="ms-1 me-1"
            href="https://www.amazon.com/Zero-to-Unicorn/dp/B0C382S611/ref=sr_1_4?crid=EM1DOM1NTBPB&keywords=Zero+To+Unicorn&qid=1683080281&sprefix=zero+to+unicorn%2Caps%2C191&sr=8-4"
            target="_blank"
          >
            <ListenAmazonPodcasts />
          </a>
        </div> */}

        <div className="d-flex justify-content-center mt-5">
          <img
            className="img-fluid z-depth-1 rounded"
            data-aos={asoEffect}
            src={image}
            loading="lazy"
            decoding="async"
            alt={imageAlt}
          />
        </div>
      </div>
    </section>
  )
}
/** export */
export default HeroBottomShowSection
