import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import SubmitShowsWaitingListSection from "../components/static-sections/SubmitShowsWaitingListSection"
import ShowsSection from "../components/static-sections/ShowsSection"
import ShowsYouTubeSection from "../components/static-sections/ShowsYouTubeSection"

/** dynamic sections */
import HeroTopSection from "../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../components/dynamic-sections/RibbonSection"
import HeroBottomSection from "../components/dynamic-sections/HeroBottomSection"
import HeroBottomImageSection from "../components/dynamic-sections/HeroBottomImageSection"
import HeroBottomShowSection from "../components/dynamic-sections/HeroBottomShowSection"
import HeroBottomShowSectionEdge from "../components/dynamic-sections/HeroBottomShowSectionEdge"
import AwardsSection from "../components/dynamic-sections/AwardsSection"
import YouTubeShowSection from "../components/dynamic-sections/YouTubeShowSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../assets/norhart-icon.svg"
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const ShowsPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/shows/",
          name: "Shows | Norhart",
          image: `${config.siteMetadata.siteUrl}/shows/norhart-shows-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Shows | Norhart"
      description="Watch and Listen to Norhart's CEO Mike Kaedings Unicorn Shows on Apple, Google, Spotify, and More."
      keywords="norhart, Shows, Shows, apple, google, spotify"
      imageTwitter={`${config.siteMetadata.siteUrl}/shows/norhart-shows-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/shows/norhart-shows-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Norhart Shows"
        subtitle="YouTube and Apple Podcast Shows"
        imageTitle="Norhart Support"
        image="/shows/norhart-shows-blue-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Join The Journey"
        tagLine="Watch and Listen to Our Unicorn Shows on Apple® Podcasts"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <HeroBottomSection
        backgroundColorOne={colorPalette.heroRibbon}
        backgroundColorTwo={colorPalette.navBottom}
        icon={NorhartBlackCircleIcon}
        iconTitle="Our Shows"
        title="Watch and Listen To Our Binge Worthy Shows"
        subTitle="As a society we celebrate unicorns.  These fast growing private companies that reach a billion dollar valuation. You may glorify or vilify them.  About the only thing you can't do is ignore them because they change things. - Mike Kaeding, CEO Norhart"
        image="/shows/norhart-brand-shows.png"
        imageAlt=""
        textColor="#FFFFFF"
      />

      <HeroBottomShowSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroTop}
        icon="{NorhartBlackCircleIcon}"
        iconTitle="Follow Our Show"
        title="Zero To Unicorn"
        subTitle="This season of Zero to Unicorn, we’ll hear directly from the Kaeding family as they recount their history of founding and operating Norhart, a unicorn-level business in the construction industry with a mission to positively impact the way people live. 
"
        image="/shows/zero-to-unicorn/norhart-zero-to-unicorn-hero.png"
        imageAlt="Zero To Unicorn Podcast"
        textColor="#FFFFFF"
        buttonName="Listen on Apple Podcast"
        buttonUrl="https://podcasts.apple.com/us/podcast/zero-to-unicorn/id1685311966"
      />

      {/* <YouTubeShowSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroTop}
        icon={NorhartBlackCircleIcon}
        iconTitle="Follow Our Show"
        title="Fearlessly Unicorn"
        subtitle="Watch Our Super Popular YouTube Short -  Kids Pulled Over by Real Cops for Driving. You Will Be Hooked In Seconds."
        colorPalette={colorPalette}
        youtubeUrl="https://youtu.be/HSHL4cpJ9Fo"
      />

      <HeroBottomImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroTop}
        icon={NorhartBlackCircleIcon}
        iconTitle=""
        title=""
        subTitle=""
        image="/shows/fearlessly-unicorn/norhart-fearlessly-unicorn-hero.png"
        imageAlt="Zero To Unicorn Podcast"
        textColor="#FFFFFF"
      /> */}

      {/* <HeroBottomShowSectionEdge
        backgroundColorOne="#333333"
        backgroundColorTwo="#111111"
        icon="{NorhartBlackCircleIcon}"
        iconTitle="Follow Our Show"
        title="Entrepreneur's Edge"
        subTitle="Entrepreneur's Edge is your passport to a global adventure in entrepreneurship. Join me, Mike Kaeding, the owner of Norhart, a $200 million real estate company, as we connect with successful individuals from around the world."
        image="/shows/entrepreneurs-edge/norhart-entrepreneurs-edge-hero.png"
        imageAlt="Zero To Unicorn Podcast"
        textColor="#FFFFFF"
        buttonName="Watch on YouTube"
        buttonUrl="https://www.youtube.com/@EntrepreneursEdgePodcast"
      /> */}
    </Layout>
  )
}

/** export */
/** export */
export default ShowsPage
