import { navigate } from "gatsby"
import React, { useEffect } from "react"
import ShowsPage from "../shows"

interface Props {
  location: { state: { formCompleted: boolean } }
}

const ShowsThanks = (props: Props) => {
  useEffect(() => {
    if (!props.location.state?.formCompleted) {
      navigate("/shows")
    }
  }, [props])

  return <ShowsPage didSubmit />
}

/** export */
export default ShowsThanks
